.popover-mobile{
    .ant-popover-content{
        .ant-popover-arrow{
            height: .07rem;
            width: .07rem;
            top: .05rem;
        }
        .ant-popover-inner{
            .ant-popover-inner-content{
                padding: .12rem .16rem;
                .language-item{
                    line-height: .28rem;
                    .language-item-span{
                        font-size: .14rem;
                    }
                }
            }
        }
    }
}
.index-page{
    background: #f7f8fa;
    height: 100%;
    .index-header{
        height: .6rem;
        width: 100%;
        padding-top: .2rem;
        background: #151f30;
        border-bottom: 1px solid #202a3a;
        .logo{
            width: 1.14rem;
            height: .23rem;
            margin-left: .15rem;
            float: left;
        }
        .language-box-mobile{
            float: right;
            margin-right: .15rem;
            span{
                color: #fff;
                cursor: pointer;
            }
            .current-lang{
                font-size: .14rem;
                margin-right: .06rem;
            }
            .anticon{
                font-size: .14rem;
            }
        }
    }
    .banner{
        position: relative;
        height: 3.4rem;
        padding-top: .3rem;
        background: #151f30;
        color: #fff;
        text-align: center;
        .banner-title{
            width: 2.2rem;
            margin: 0 auto .1rem;
            font-size: .24rem;
            line-height: 1.5;
        }
        .banner-desc{
            position: absolute;
            z-index: 5;
            width: 3.2rem;
            font-size: .12rem;
            line-height: 1.6;
            .banner-desc-content{
                width: 2.4rem;
                margin: 0 auto;
            }
        }
        .banner-img{
            position: absolute;
            bottom: .4rem;
            left: 0;
            width: 3.2rem;
        }
    }
    .production-service-box{
        .title{
            margin-top: .3rem;
            font-size: .2rem;
            color: #262627;
            text-align: center;
            line-height: 1.7;
        }
        .desc{
            margin-bottom: .1rem;
            padding: 0 .2rem;
            font-size: .12rem;
            color: #9b9ea0;
            text-align: center;
            line-height: 2;
        }
        .content{
            padding: 12px;
            background: #f7f8fa;
            .my-accordion{
                &::before{
                    display: none;
                }
                .pad{
                    margin-bottom: .12rem;
                    .am-accordion-header{
                        height: .48rem;
                        line-height: .48rem;
                        color: #262627;
                        font-size: .14rem;
                        .custom-header{
                            .accordion-icon{
                                width: .32rem;
                                margin-right: .12rem;
                            }
                            .hot{
                                float: right;
                                margin-top: .12rem;
                                margin-right: .2rem;
                                height: .2rem;
                                line-height: .2rem;
                                padding: 0 .04rem;
                                border: 1px solid #ff8c23;
                                color: #ff8a1f;
                            }
                        }
                        .arrow{
                            width: .15rem;
                            height: .15rem;
                            top: .17rem;
                        }
                    }
                }
                .am-accordion-item-active{
                    .am-accordion-content-active{
                        .am-accordion-content-box{
                            padding: .16rem;
                            color: #73777a;
                            font-size: .12rem;
                            line-height: 1.6;
                        }
                    }
                }
            }
        }
    }
    .swiper-box{
        height: 5.16rem;
        padding-top: .3rem;
        background: #151f30;
        color: #fff;
        .title{
            font-size: .2rem;
            text-align: center;
            line-height: 1.7;
        }
        .desc{
            // margin-top: .1rem;
            margin-bottom: .2rem;
            font-size: .12rem;
            color: #9b9ea0;
            text-align: center;
            line-height: 2;
        }
        .am-tabs{
            height: .44rem;
            margin-bottom: .3rem;
            .am-tabs-tab-bar-wrap{
                .am-tabs-default-bar-tab{
                    height: .44rem;
                    font-size: .14rem;
                    &::after{
                        display: none;
                    }
                }
            }
        }
        .swiper-container{
            .swiper-slide{
                height: 3rem;
                background: #151f30;
                .cover-floor{
                    text-align: center;
                    .item-icon{
                        margin: .3rem 0;
                        width: .38rem;
                    }
                    .item-title{
                        margin-bottom: .15rem;
                        font-size: .16rem;
                        font-weight: 500;
                        line-height: 2;
                    }
                    .item-desc{
                        font-size: .14rem;
                        line-height: 1.8;
                        padding: 0 .3rem;
                    }
                }
            }
            .swiper-slide-active{
                // width: 2.4rem!important;
                height: 3rem;
                .cover-floor{
                    width: 100%;
                    height: 100%;
                    background: rgba(3,130,244,.6)
                }
                // background: #0382f4;
            }
        }
        .am-tabs-content-wrap{
            height: 0;
        }
        .am-tabs-pane-wrap{
            height: 0;
        }
        .am-tabs{
            .am-tabs-default-bar-nextpage{
                background: transparent;
            }
            .am-tabs-default-bar-prevpage{
                background: transparent;
            }
        }
    }
    .platform-advantages{
        height: 2.9rem;
        padding-top: .24rem;
        background: #f7f8fa;
        .title{
            font-size: .2rem;
            color: #151f30;
            text-align: center;
            line-height: 1.7;
        }
        .desc{
            margin-bottom: .2rem;
            font-size: .12rem;
            color: #9b9ea0;
            text-align: center;
            line-height: normal;
        }
        .advantage-row{
            height: .56rem;
            margin-bottom: .28rem;
            display: flex;
            .item{
                flex: 1;
                font-size: .12rem;
                color: #333;
                text-align: center;
                .desc{
                    margin-top: .08rem;
                    color: #333;
                }
            }
        }
    }
    .partner-box{
        padding-top: .24rem;
        .title{
            margin-bottom: .2rem;
            font-size: .2rem;
            color: #151f30;
            text-align: center;
        }
        .desc{
            margin: .1rem .2rem;
            font-size: .12rem;
            color: #9b9ea0;
            text-align: center;
        }
        .partner-row{
            display: flex;
            margin-bottom: .2rem;
            .partner-icon{
                flex: 1;
                // width: 1rem;
                height: .32rem;
            }
        }
    }
    .travel-box{
        // height: 3.2rem;
        padding-top: .34rem;
        padding-bottom: .2rem;
        background: #f7f8fa;
        .title{
            text-align: center;
            font-size: .2rem;
            margin-bottom: .2rem;
        }
        .step{
            width: 2.9rem;
            // height: 2rem;
            margin: 0 auto;
            .item{
                display: flex;
                margin-bottom: .2rem;
                .img{
                    img{

                        width: .32rem;
                        // height: .24rem;
                        margin-right: .16rem;
                        line-height: .32rem;
                        text-align: center;
                    }
                }
                .text-box{
                    flex: 1;
                    .item-title{
                        margin-bottom: .08rem;
                        font-size: .16rem;
                        line-height: .24rem;
                        color: #262627;
                    }
                    .item-desc{
                        line-height: 1.6;
                        color: #73777a;
                        font-size: .12rem;
                        
                    }
                }
            }
        }
    }
    .footer-box{
        // height: 4rem;
        background: #151f30;
        padding: .3rem .12rem .18rem;
        .footer-content{
            .qr-code{
                width: .96rem;
                height: .96rem;
                margin: 0 auto;
                img{
                    width: .96rem;
                }
            }
            .qr-code-text-zh{
                width: 1.2rem;
                margin: .12rem auto .18rem;
                font-size: .12rem;
                color: #9b9ea0;
                text-align: center;
                line-height: 1.7;
            }
            .qr-code-text-en{
                width: 2.6rem;
                margin: .12rem auto .18rem;
                font-size: .12rem;
                color: #9b9ea0;
                text-align: center;
                line-height: 1.7;
            }
            .tel{
                height: .4rem;
                line-height: .4rem;
                text-align: center;
                background: #0094fe;
                .tel-text{
                    display: block;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    font-size: .14rem;
                }
            }
            .email{
                height: .4rem;
                margin: .12rem auto .3rem;
                line-height: .4rem;
                border: 1px solid #9b9ea0;
                text-align: center;
                .email-text{
                    display: block;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    font-size: .14rem;
                }
            }
            .footer-logo{
                margin-bottom: .18rem;
                text-align: center;
                img{
                    height: .28rem;
                }
            }
            .footer-text{
                font-size: .12rem;
                text-align: center;
                color: #9b9ea0;
            }
        }
    }
}