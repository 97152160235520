html {
    font-size: 31.25vw;
}

html,
body,
#root,
.App {
    width: 100%;
    height: 100%;
}

body,
ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
dl,
dd,
form,
input,
textarea,
button,
select {
    padding: 0;
    margin: 0;
}

h1,
h2,
h3,
h4 {
    font-weight: normal;
}

body {
    font-size: 14px;
    font-family: "PingFangSC", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
    line-height: 1;
}

ul,
li {
    list-style: none;
}

i {
    font-style: normal;
}

input,
button,
img {
    border: none;
}

a {
    text-decoration: none;
    color: #333;
    font-size: 12px;
}

a:hover {
    text-decoration: none;
}

a:visited {
    text-decoration: none;

}

a:active {
    text-decoration: none;

}

a:link {
    text-decoration: none;

}

input,
button,
select,
textarea {
    outline: none
}


.clearfix::after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

image::after {
    border: none;
}

//去除下边线
button::after {
    border: none;
}

//去除下边线

